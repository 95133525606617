import type { GetServerSideProps } from "next";
import Head from "next/head";
import Link from "next/link";
import React, { memo } from "react";
import styled from "styled-components";
import Button from "../components/buttons/Button";
// import DashboardContributionBanner from "../components/dashboard/DashboardContributionBanner";
import DashboardGuideBanner from "../components/dashboard/DashboardGuideBanner";
// import DashboardSocialShoutCards from "../components/dashboard/DashboardSocialShoutCards";
import DashboardFeaturedContent from "../components/dashboard/DashboardFeaturedContent";
// import DashBoardItemCards from "../components/dashboard/DashboardItemCards";
// import { DashboardNetworkCards } from "../components/dashboard/DashboardNetworkCards";
import DashboardProjectCatalystBanner from "../components/dashboard/DashboardProjectCatalystBanner";
/*
  Not needed temporarily, hiding until have content

  import DashboardSection, {
    FeaturedDashboardSectionContainer
  } from "../components/dashboard/DashboardSection";
*/
import DashboardSection from "../components/dashboard/DashboardSection";
import { DashboardSocialCards } from "../components/dashboard/DashboardSocialCards";
import { dashboardStickyHeaderVariables } from "../components/dashboard/DashboardStickyHeader";
import { PageSearchInputOnScreenSentinal } from "../components/dashboard/PageSearchInputOnScreenSentinal";
// import HomepageVideo from "../components/homepage-video/HomepageVideo";
import ContentCard from "../components/item/content-card/ContentCard";
import {
  ContentGridItem,
  ContentGrid
} from "../components/page-partials/ContentCardGrid";
import { LandingGridContainer } from "./content-grid";
import { PageContainer } from "../components/page-partials/PageContainer";
import AutoComplete from "../components/search/AutoComplete";
import createServerContext from "../context/server-context";
import {
  // findCardano101Items,
  findFeaturedItems,
  findMostViewedItems
} from "../data/db";
import { Item } from "../data/db/types";
import { setCacheHeader } from "../page-cache";
import { pxToRem } from "@input-output-hk/px-to-rem";
import colors from "../tokens/colors";
import {
  forNarrowPhoneOnly,
  forPhoneOnly,
  forTabletLandscapeUp,
  forTabletPortraitDown,
  forTabletPortraitUp
} from "../tokens/media-queries";
import zIndex from "../tokens/z-index";

const HomepageBackdrop = styled.div`
  --background-image-width: ${pxToRem(1080)};
  --background-position-x: calc(100% + var(--background-image-width) / 3);
  --background-position-y: calc(0% - var(--background-image-width) / 2);
  background-image: url("/homepage-video/homepage-video-background.png");
  background-repeat: no-repeat;

  background-size: var(--background-image-width);
  background-position: var(--background-position-x) var(--background-position-y);

  @media ${forTabletPortraitDown} {
    background-position: var(--background-position-x)
      calc(0% - var(--background-image-width) / 5);
  }
`;

const DashboardIntroSection = styled.div`
  width: 100%;
  max-width: var(--page-max-width);
  margin: auto;
  padding-left: var(--spacing-large);
  padding-right: var(--spacing-large);
  padding-top: var(--spacing-large);
  margin-bottom: ${pxToRem(130)};
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(${pxToRem(340)}, 1fr));

  @media ${forTabletPortraitDown} {
    display: flex;
    flex-direction: column;
    padding-top: 0;
  }
`;

const DashboardTitleSearchWrapper = styled.div`
  min-width: ${pxToRem(515)};
  z-index: ${zIndex.search};

  @media ${forTabletPortraitDown} {
    min-width: ${pxToRem(330)};
  }
`;

const DashboardContainer = styled.div`
  ${dashboardStickyHeaderVariables}

  position: relative;
`;
const DashboardTitle = styled.h1`
  font-weight: normal;
  font-size: ${pxToRem(44)};
  line-height: ${pxToRem(50)};
  margin-bottom: ${pxToRem(8)};
  margin-top: ${pxToRem(55)};
  width: initial;

  @media ${forTabletLandscapeUp} {
    width: ${pxToRem(450)};
  }

  @media ${forTabletPortraitDown} {
    font-size: ${pxToRem(35)};
    margin-top: ${pxToRem(24)};
  }

  @media ${forPhoneOnly} {
    width: ${pxToRem(350)};
  }

  @media ${forNarrowPhoneOnly} {
    width: ${pxToRem(300)};
  }
`;
const DashboardSubtitle = styled.h2`
  margin-top: ${pxToRem(8)};
  margin-bottom: ${pxToRem(35)};
  color: ${colors.primary.slateGrey};
  font-weight: normal;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(28)};
  max-width: ${pxToRem(400)};
  width: initial;

  @media ${forTabletPortraitUp} {
    max-width: initial;
  }

  @media ${forTabletLandscapeUp} {
    max-width: ${pxToRem(400)};
    width: ${pxToRem(400)};
  }
`;

const PageSearchWrapper = styled.div`
  padding-bottom: ${pxToRem(80)};
`;

const SeeMoreButton = styled(Button)`
  margin-top: ${pxToRem(50)};
  align-self: center;
`;

const PageHead = memo(() => (
  <>
    <Head>
      <title>Home | Essential Cardano</title>
      <meta name="description" content="Essential Cardano" />
      <link rel="icon" href="/favicon.ico" />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link
        rel="canonical"
        href={"https://www.essentialcardano.io/"}
        key="canonical"
      />
    </Head>
  </>
));
PageHead.displayName = "PageHead";

type Props = {
  mostViewedItems: Item[];
  featuredItems: Item[];
};

const Dashboard: React.FC<Props> = ({ mostViewedItems, featuredItems }) => {
  return (
    <HomepageBackdrop>
      <PageHead />
      <DashboardIntroSection>
        <DashboardTitleSearchWrapper>
          <DashboardTitle>Your definitive guide to Cardano</DashboardTitle>
          <DashboardSubtitle>
            Explore and contribute to the new community resource platform
          </DashboardSubtitle>
          <PageSearchInputOnScreenSentinal />
          <PageSearchWrapper>
            <AutoComplete
              appearance="default"
              searchPlaceholderText={"Find resources and more..."}
            />
          </PageSearchWrapper>
        </DashboardTitleSearchWrapper>
        {/* <HomepageVideo /> */}
      </DashboardIntroSection>
      <PageContainer>
        <DashboardGuideBanner />
        <DashboardContainer>
          <DashboardSection
            title="Featured content"
            subtitle="Discover top picks from around the ecosystem"
          >
            <LandingGridContainer>
              <DashboardFeaturedContent featuredItems={featuredItems} />
            </LandingGridContainer>
          </DashboardSection>
          <DashboardSection
            title="Trending content"
            subtitle="Check out the most popular content on the platform"
          >
            <LandingGridContainer>
              <ContentGrid>
                {mostViewedItems?.map((item, idx) => {
                  return (
                    <ContentGridItem key={idx}>
                      <ContentCard key={idx} item={item} />
                    </ContentGridItem>
                  );
                })}
              </ContentGrid>
              <Link href={"/content"} passHref={true}>
                <SeeMoreButton as="a" appearance="primary" size="default">
                  View more
                </SeeMoreButton>
              </Link>
            </LandingGridContainer>
          </DashboardSection>
          {/* Disable twitter shout cards for now */}
          {/* <DashboardSection
              title="Shoutouts and Support"
              subtitle="Noteworthy mentions from the Twittersphere"
              linkTo="/twitter-wall"
              linkToLabel="View more"
            >
              <DashboardSocialShoutCards />
            </DashboardSection> */}
          {/* <DashboardSection
              title="Visualise our network"
              subtitle="TEST BUILD NOTE: This is just placeholder for now while we wait for a source for these stats"
              linkTo="/network"
              linkToLabel="View all"
            >
              <DashboardNetworkCards />
            </DashboardSection> */}
          <DashboardSection
            title="Join our community"
            subtitle="Follow our social media channels for news and updates from the Cardano ecosystem"
            linkTo="/community"
            linkToLabel="Explore"
          >
            <DashboardSocialCards limit isOnIndex={true} />
          </DashboardSection>
          <DashboardProjectCatalystBanner />
        </DashboardContainer>
      </PageContainer>
    </HomepageBackdrop>
  );
};

export default Dashboard;

export const getServerSideProps: GetServerSideProps<Props> = async (
  baseCtx
) => {
  const ctx = createServerContext(baseCtx);
  const [featuredItems, mostViewedItems] = await Promise.all([
    findFeaturedItems(ctx),
    findMostViewedItems(ctx)
  ]);

  setCacheHeader(ctx, baseCtx.res);

  return {
    props: {
      featuredItems,
      mostViewedItems
    }
  };
};
